import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { AppSEOImage } from '../../appSEO';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';
import { isString } from 'lodash';

const HeroTitle = ({
  imgSrc,
  children,
  small = false,
}: {
  imgSrc: AppGatsbyImageFluid | string;
  children: ReactNode;
  small?: boolean;
}) => (
  <>
    <AppSEOImage image={isString(imgSrc) ? imgSrc : imgSrc.src!} />
    <div
      className={classnames('hero hero--title', small && 'hero--title-small')}
    >
      {isString(imgSrc) ? (
        // TODO this is probably wrong but can't handle it now
        <div
          className="hero-background"
          style={{ backgroundImage: `url(${imgSrc})` }}
        />
      ) : (
        <AppGatsbyImage
          fluid={imgSrc}
          className="hero-background"
          style={{
            position: 'absolute',
          }}
        />
      )}

      <div className="container hero-caption">{children}</div>
    </div>
  </>
);

export default HeroTitle;
