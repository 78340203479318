import React, { useEffect, useState } from 'react';
import { MainLayout } from 'layout/mainLayout';

import HeroTitle from 'components/hero/heroTitle';
import { H1, H2, H5 } from 'primitives/appTitle';
import {
  ShText,
  useShIntlLocale,
  useShTranslate,
} from '@shoootin/translations';

import './interview.less';
import { useAsync } from 'react-async-hook';
import {
  cancelInterview,
  confirmInterviewSlot,
  getInterviewSlots,
  getPhotographerAndInitInterviewSlots,
} from 'appAPI';
import AppSection from 'primitives/appSection';
import { InterviewConfirmedDTO, Slot, UserInfosValues } from 'appAPITypes';
import { InterviewPageTranslations } from './interviewPageTranslations';
import classnames from 'classnames';
import { FrontCommonMessages } from 'appCommonTranslations';
import { PageErrorContent } from '../pageError';
import { usePageParams } from 'layout/appContext';
import { graphql } from 'gatsby';
import { getFluidImage } from '../../appGatsbyImageUtils';

import { InterviewPagePhotoQuery } from '../../appGraphQLTypes';
import { getFrontMessage } from '../../appMessageDefinitions';

import {
  ShButtonAsync,
  ShDatePicker,
  ShInputSelect,
  ShSpinner,
  ShTitle,
} from '@shoootin/components-web';
import {
  formatDateTimeForDB,
  nextWorkingDay,
  ShMoment,
  ShMomentType,
} from '@shoootin/utils';

import {
  ShLanguageLocale,
  ShLanguageLocalesOptions,
  ShTimeZone,
  ShTimeZoneOptions,
} from '@shoootin/config';

import { ShFonts } from '@shoootin/design-tokens';

const SlotElement = ({
  format,
  onClick,
  active,
}: {
  format: String;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <div
      className={classnames('hour btn float light', active ? 'active' : '')}
      onClick={onClick}
    >
      {format}
    </div>
  );
};

const getSlots = (
  photographerId: string,
  date: ShMomentType,
  preferredLanguage: ShLanguageLocale,
) =>
  getInterviewSlots({
    photographerId,
    date: formatDateTimeForDB(date),
    preferredLanguage,
  });

const InterviewConfirmed = ({
  interview,
  timeZone,
  onCancel,
}: {
  interview: InterviewConfirmedDTO;
  timeZone: string;
  onCancel: () => void;
}) => {
  return (
    <div
      css={{
        flexDirection: 'column',
      }}
    >
      <div>
        <ShText
          message={InterviewPageTranslations.interviewConfirmed}
          values={{
            interviewDay: <b>{ShMoment(interview.start).format('LL')}</b>,
            interviewDateStart: (
              <b>{ShMoment(interview.start).tz(timeZone).format('LT')}</b>
            ),
            interviewDateEnd: (
              <b>{ShMoment(interview.end).tz(timeZone).format('LT')}</b>
            ),
            interviewer: <b>{interview.adminName}</b>,
            googleMeetUrl: <b>{interview.googleMeetUrl}</b>,
          }}
        />
      </div>
      <div css={{ marginTop: '1em' }}>
        <ShButtonAsync onClick={onCancel} variant={'blackLL'} size={'m'}>
          <ShText message={getFrontMessage('common_actions_cancel')} />
        </ShButtonAsync>
      </div>
    </div>
  );
};

const InterviewContent = ({
  initialSlots,
  userInfos,
  initialInterview,
  photographerId,
  initialTimeZone,
  initialPreferredLanguage,
  initialFutureAvailableDays,
  availableLanguages,
}: {
  initialSlots: Slot[];
  userInfos: UserInfosValues;
  initialInterview: InterviewConfirmedDTO | false;
  photographerId: string;
  initialTimeZone: ShTimeZone;
  initialPreferredLanguage: ShLanguageLocale;
  initialFutureAvailableDays: string[];
  availableLanguages: ShLanguageLocale[];
}) => {
  const locale = useShIntlLocale();
  const [date, setDate] = useState<ShMomentType>(nextWorkingDay());
  const [timeZone, setTimeZone] = useState<ShTimeZone>(initialTimeZone);
  const [interview, setInterview] = useState<InterviewConfirmedDTO | false>(
    initialInterview,
  );
  const [interviewToConfirm, setInterviewToConfirm] = useState<Slot | null>(
    null,
  );

  const [preferredLanguage, setPreferredLanguage] = useState<ShLanguageLocale>(
    initialPreferredLanguage,
  );

  const [futureAvailableDays, setFutureAvailableDays] = useState<string[]>(
    initialFutureAvailableDays,
  );

  const asyncSlots = useAsync(
    getSlots,
    [photographerId, date, preferredLanguage],
    {
      initialState: () => ({
        status: 'success',
        loading: false,
        error: undefined,
        result: {
          interview: initialInterview,
          slots: initialSlots,
          futureAvailableDays: initialFutureAvailableDays,
        },
      }),
      executeOnMount: false,
    },
  );

  useEffect(() => {
    if (asyncSlots.result && asyncSlots.result.futureAvailableDays) {
      setFutureAvailableDays(asyncSlots.result.futureAvailableDays);
    }
  });

  const tr = useShTranslate();

  const onCancel = async () => {
    const result = await cancelInterview(photographerId);
    setInterview(result.interview);
    setInterviewToConfirm(null);
  };

  const onConfirm = async () => {
    const interviewConfirmedDTO = await confirmInterviewSlot({
      photographerId,
      start: interviewToConfirm!.start,
      end: interviewToConfirm!.end,
      timezone: timeZone,
      preferredLanguage,
    });
    setInterview(interviewConfirmedDTO);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AppSection
      className="left photograph-form"
      header={
        <H2>
          <ShText
            message={InterviewPageTranslations.sectionTitleHelloName}
            values={userInfos}
          />
        </H2>
      }
    >
      {interview && (
        <InterviewConfirmed
          interview={interview}
          timeZone={timeZone}
          onCancel={onCancel}
        />
      )}
      {!interview && (
        <>
          <p
            css={{
              marginBottom: '1em',
            }}
          >
            <ShText
              message={InterviewPageTranslations.sectionSubtitleCongrats}
            />
          </p>
          <p
            css={{
              marginBottom: '1em',
            }}
          >
            <ShText
              message={InterviewPageTranslations.sectionSubtitleChooseSlot}
            />
          </p>
          <div className="interviewCalendar">
            <div className="interviewDayPicker">
              <div css={{ marginBottom: 20 }}>
                <div
                  css={{
                    paddingBottom: 5,
                    fontFamily: ShFonts.secondary,
                    fontSize: 14,
                  }}
                >
                  <ShText message={FrontCommonMessages.timeZone} />
                </div>
                <ShInputSelect
                  value={timeZone}
                  onChange={(option) => {
                    setTimeZone(option);
                  }}
                  options={ShTimeZoneOptions} // TODO stop using select detect timezone from addess.
                  placeholder={tr(FrontCommonMessages.timeZone)}
                />
              </div>
              <div css={{ marginBottom: 20 }}>
                <div
                  css={{
                    paddingBottom: 5,
                    fontFamily: ShFonts.secondary,
                    fontSize: 14,
                  }}
                >
                  <ShText message={FrontCommonMessages.yourPreferredLanguage} />
                </div>
                <ShInputSelect
                  value={preferredLanguage}
                  onChange={(option) => {
                    setPreferredLanguage(option);
                  }}
                  options={ShLanguageLocalesOptions.filter((each: any) =>
                    availableLanguages.includes(each.value),
                  )}
                />
              </div>

              <ShDatePicker
                date={date}
                onDateChange={(date: ShMomentType) => {
                  setDate(date);
                  setInterviewToConfirm(null);
                }}
                isDayBlocked={(date: ShMomentType) => {
                  return !futureAvailableDays.includes(
                    date.format('YYYY-MM-DD'),
                  );
                }}
                firstAvailableDate={nextWorkingDay()}
              />
            </div>
            <div className="interviewTimePicker">
              <div
                css={{
                  flexDirection: 'column',
                }}
              >
                <H5
                  css={{
                    marginBottom: '1em',
                  }}
                >
                  {date && (
                    <ShText
                      message={InterviewPageTranslations.availabilityTitle}
                      values={{
                        date: date.format('LL'),
                      }}
                    />
                  )}
                </H5>

                <div className="">
                  {asyncSlots.loading && (
                    <div css={{ margin: 50 }}>
                      <ShSpinner size={'m'} />
                    </div>
                  )}
                  {asyncSlots.error && (
                    <div css={{ margin: 50 }}>Technical error</div>
                  )}
                  {asyncSlots.result && (
                    <>
                      {asyncSlots.result.slots.length === 0 ? (
                        <ShText
                          message={
                            InterviewPageTranslations.noAvailabilityTitle
                          }
                          values={{
                            date: date.format('LL'),
                          }}
                        />
                      ) : (
                        <>
                          <small
                            css={{
                              marginBottom: '1em',
                            }}
                          >
                            <ShText
                              message={InterviewPageTranslations.slotDuration}
                            />
                          </small>

                          <div
                            css={{
                              display: 'flex',
                              flexFlow: 'row wrap',
                            }}
                          >
                            {asyncSlots.result.slots.map((slot: Slot) => (
                              <SlotElement
                                key={slot.start}
                                format={ShMoment(slot.start)
                                  .tz(timeZone)
                                  .format('LT')}
                                active={
                                  interviewToConfirm
                                    ? slot.start === interviewToConfirm.start
                                    : false
                                }
                                onClick={() => {
                                  setInterviewToConfirm(slot);
                                }}
                              />
                            ))}
                          </div>
                          {interviewToConfirm && (
                            <>
                              <div
                                css={{
                                  marginTop: '2em',
                                }}
                              >
                                <ShTitle size={5}>
                                  <ShText
                                    message={
                                      InterviewPageTranslations.slotConfirmation
                                    }
                                    values={{
                                      interviewDay: ShMoment(
                                        interviewToConfirm.start,
                                      ).format('LL'),
                                      interviewDateStart: ShMoment(
                                        interviewToConfirm.start,
                                      )
                                        .tz(timeZone)
                                        .format('LT'),
                                      interviewDateEnd: ShMoment(
                                        interviewToConfirm.end,
                                      )
                                        .tz(timeZone)
                                        .format('LT'),
                                    }}
                                  />
                                </ShTitle>
                                <div css={{ marginTop: 20, display: 'flex' }}>
                                  <div css={{ marginRight: 20 }}>
                                    <ShButtonAsync onClick={onConfirm}>
                                      <ShText
                                        message={getFrontMessage(
                                          'common_actions_validate',
                                        )}
                                      />
                                    </ShButtonAsync>
                                  </div>
                                  <div>
                                    <ShButtonAsync
                                      variant={'light'}
                                      onClick={() => {
                                        setInterviewToConfirm(null);
                                      }}
                                    >
                                      <ShText
                                        message={getFrontMessage(
                                          'common_actions_cancel',
                                        )}
                                      />
                                    </ShButtonAsync>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AppSection>
  );
};

const InterviewLoader = ({ photographerId }: { photographerId: string }) => {
  const joinFormAsync = useAsync(getPhotographerAndInitInterviewSlots, [
    photographerId,
  ]);

  return (
    <>
      {joinFormAsync.loading && (
        <div css={{ margin: 50 }}>
          <ShSpinner size={'m'} />
        </div>
      )}
      {joinFormAsync.error && (
        <H5 css={{ textAlign: 'center' }}>
          This page is only available for photographer with pending interviews.
        </H5>
      )}
      {joinFormAsync.result && (
        <InterviewContent
          photographerId={photographerId}
          initialInterview={joinFormAsync.result.interview}
          userInfos={joinFormAsync.result.userInfos}
          initialSlots={joinFormAsync.result.slots}
          initialTimeZone={joinFormAsync.result.timeZone}
          initialPreferredLanguage={joinFormAsync.result.preferredLanguage}
          initialFutureAvailableDays={joinFormAsync.result.futureAvailableDays}
          availableLanguages={joinFormAsync.result.availableLanguages}
        />
      )}
    </>
  );
};

const InterviewPage = ({ data }: { data: InterviewPagePhotoQuery }) => {
  const heroImage = getFluidImage(data.heroImage!);
  const { photographerId } = usePageParams();
  return (
    <MainLayout
      className="page-photograph"
      showContact={false}
      pageName="interview"
    >
      <HeroTitle imgSrc={heroImage} small={true}>
        <H1 modifier="hero-title">
          <ShText message={InterviewPageTranslations.pageTitle} />
        </H1>
      </HeroTitle>
      {photographerId ? (
        <InterviewLoader photographerId={photographerId} />
      ) : (
        <PageErrorContent />
      )}
    </MainLayout>
  );
};

export default InterviewPage;

export const query = graphql`
  query InterviewPagePhoto {
    heroImage: file(relativePath: { eq: "photographer_header.jpg" }) {
      ...AppFluidHeroImageFragment
    }
  }
`;
