import { defineMessages } from 'react-intl';

const interviewPagePrefix = 'front_interview';

export const InterviewPageTranslations = defineMessages({
  pageTitle: {
    id: `${interviewPagePrefix}_pageTitle`,
    defaultMessage: 'Your application',
  },
  sectionTitleHelloName: {
    id: `${interviewPagePrefix}_sectionTitleHelloName`,
    defaultMessage: 'Hello {firstName},',
  },
  sectionSubtitleCongrats: {
    id: `${interviewPagePrefix}_sectionSubtitleCongrats`,
    defaultMessage: 'Congratulations, your application has been accepted.',
  },
  sectionSubtitleChooseSlot: {
    id: `${interviewPagePrefix}_sectionSubtitleChooseSlot`,
    defaultMessage:
      'Please choose a timeslot to have an interview with our photographer team.',
  },
  availabilityTitle: {
    id: `${interviewPagePrefix}_availabilityTitle`,
    defaultMessage: 'Available slots for {date}',
  },
  noAvailabilityTitle: {
    id: `${interviewPagePrefix}_noAvailabilityTitle`,
    defaultMessage: 'No available slots for {date}',
  },
  interviewConfirmed: {
    id: `${interviewPagePrefix}_interviewConfirmed`,
    defaultMessage:
      'Your interview is confirmed on {interviewDay} from {interviewDateStart} to {interviewDateEnd}. You can cancel this interview and re-schedule one if needed.',
  },
  slotDuration: {
    id: `${interviewPagePrefix}_slotDuration`,
    defaultMessage: 'Duration : 20 minutes',
  },
  slotConfirmation: {
    id: `${interviewPagePrefix}_slotConfirmation`,
    defaultMessage:
      'Confirm your interview on {interviewDay} from {interviewDateStart} to {interviewDateEnd} ?',
  },
});
